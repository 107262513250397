import { useEffect } from "react";
import { useState } from "react";

export default function Countdown({
  till,
  cname,
  format = "mm:ss",
  timeout_callback = () => {},
}) {
  const time_left = () => {
    const now = new Date();
    const refresh = till;
    const diff = refresh - now;
    const diff_seconds = Math.floor(diff / 1000);
    const diff_minutes = Math.floor(diff_seconds / 60);
    const diff_hours = Math.floor(diff_minutes / 60);

    return {
      hours: diff_hours,
      minutes: diff_minutes % 60,
      seconds: diff_seconds % 60,
    };
  };

  const is_over = (t) => {
    return t.hours <= 0 && t.minutes <= 0 && t.seconds <= 0;
  };

  const [timeLeft, setTimeLeft] = useState(time_left());

  const time_human_redable = (t) => {
    if (t.minutes < 0) t.minutes = 0;
    if (t.seconds < 0) t.seconds = 0;
    if (t.hours < 0) t.hours = 0;

    const minutes_padded = t.minutes.toString().padStart(2, "0");
    const seconds_padded = t.seconds.toString().padStart(2, "0");

    if (format === "mm:ss") {
      return `${minutes_padded}:${seconds_padded}`;
    } else if (format === "hh:mm:ss") {
      const hours_padded = t.hours.toString().padStart(2, "0");
      return `${hours_padded}:${minutes_padded}:${seconds_padded}`;
    } else {
      return "Invalid format";
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(time_left());
    }, 1000);

    return () => clearInterval(interval);
  }, [till]);

  useEffect(() => {
    if (is_over(timeLeft)) {
      timeout_callback();
    }
  }, [timeLeft]);

  return (
    <div className="countdown">
      <span className={cname}>{time_human_redable(timeLeft)}</span>
    </div>
  );
}
