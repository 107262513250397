import { useLayoutEffect, useState } from "react";
import { fetchWeather } from "../repositories/WeatherRepository";
import { WeatherDayDTO } from "../DTOs/WeatherDayDTO";

export function WeatherWidget({ refresh }) {
  const [todayWeatherData, setTodayWeatherData] = useState(new WeatherDayDTO());

  const [tomorrowWeatherData, setTomorrowWeatherData] = useState(
    new WeatherDayDTO()
  );

  useLayoutEffect(() => {
    async function fetchWeatherData() {
      try {
        let result = await fetchWeather(new Date().toJSON().slice(0, 10));

        setTodayWeatherData(new WeatherDayDTO(result));

        result = await fetchWeather(
          new Date(new Date().setDate(new Date().getDate() + 1))
            .toJSON()
            .slice(0, 10)
        );

        setTomorrowWeatherData(new WeatherDayDTO(result));
      } catch (error) {
        console.log(error);
      }
    }
    fetchWeatherData().catch((error) => console.log(error));
  }, [refresh]);

  return (
    <div className="flex-3 md:basis-full lap:basis-1/2">
      <div className="mx-auto">
        <div className="mx-auto mt-14 grid  auto-rows-fr grid-cols-1 gap-8 ">
          <article className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-12 pb-52 lg:pb-32 pt-52 md:pb-40">
            <img
              src="/images/weather/clouds-3488632_1280.jpg"
              alt=""
              className="absolute inset-0 -z-10 h-full w-full object-cover"
            />
            <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900/90 via-gray-900/80" />
            <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

            <div className="flex flex-row space-x-4 absolute top-12 items-center font-bold xs:top-6 xs:translate-x-1/2 xs:right-1/2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 text-white"
              >
                <path
                  fillRule="evenodd"
                  d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                  clipRule="evenodd"
                />
              </svg>

              <h2 className="text-white font-bold text-lg md:text-2xl xs:text-nowrap">
                {todayWeatherData.location.name}
              </h2>
            </div>

            <div className="flex flex-col lg:flex-row top-1/4 lg:top-1/3 absolute justify-between w-full text-md md:text-xl space-y-6 lg:space-y-0 xs:w-auto xs:top-14 xs:translate-x-1/2 xs:right-1/2 md:flex-row">
              <div className="space-y-4 flex-[1] xs:space-y-3">
                <div className="flex xs:justify-center">
                  <time className="text-white text-lg md:text-2xl">
                    {todayWeatherData.getFormattedDate()}
                  </time>
                </div>

                <div className="flex xs:justify-center">
                  <div className="flex flex-row text-white space-x-4 items-center">
                    <img
                      className="h-8 w-8"
                      alt=""
                      src={todayWeatherData.day.condition.icon}
                    />
                    <p className="text-white">
                      {todayWeatherData.day.condition.text}
                    </p>
                  </div>
                </div>

                <div className="flex xs:justify-center xs:justify-center">
                  <p className="text-white">
                    {todayWeatherData.day.mintemp_c} °C ~{" "}
                    {todayWeatherData.day.maxtemp_c} °C
                  </p>
                </div>

                <div className="flex flex-row space-x-4 xs:justify-center">
                  <div className="flex flex-row space-x-4 items-center">
                    <div>
                      <img
                        className="h-8 w-8"
                        alt=""
                        src="/images/icons/sunrise.svg"
                      />
                    </div>

                    <p className="text-white">
                      {todayWeatherData.astro.sunrise}
                    </p>
                  </div>

                  <div className="flex flex-row space-x-4 items-center xs:justify-center">
                    <div>
                      <img
                        className="h-8 w-8"
                        src="/images/icons/sunset.svg"
                        alt=""
                      />
                    </div>

                    <p className="text-white">
                      {todayWeatherData.astro.sunset}
                    </p>
                  </div>
                </div>
              </div>

              <div className="space-y-4 flex-[1] xs:space-y-3 md:!mt-0">
                <div className="flex xs:justify-center">
                  <time className="text-white text-lg md:text-2xl">
                    {tomorrowWeatherData.getFormattedDate()}
                  </time>
                </div>

                <div className="flex xs:justify-center">
                  <div className="flex flex-row text-white space-x-4 items-center">
                    <img
                      className="h-8 w-8"
                      alt=""
                      src={tomorrowWeatherData.day.condition.icon}
                    />
                    <p className="text-white">
                      {tomorrowWeatherData.day.condition.text}
                    </p>
                  </div>
                </div>

                <div className="flex xs:justify-center">
                  <p className="text-white">
                    {tomorrowWeatherData.day.mintemp_c} °C ~{" "}
                    {tomorrowWeatherData.day.maxtemp_c} °C
                  </p>
                </div>
                <div className="flex flex-row space-x-4 xs:justify-center">
                  <div className="flex flex-row space-x-4 items-center">
                    <div>
                      <img
                        className="h-8 w-8"
                        alt=""
                        src="/images/icons/sunrise.svg"
                      />
                    </div>

                    <p className="text-white">
                      {tomorrowWeatherData.astro.sunrise}
                    </p>
                  </div>

                  <div className="flex flex-row space-x-4 items-center">
                    <div>
                      <img
                        className="h-8 w-8"
                        src="/images/icons/sunset.svg"
                        alt=""
                      />
                    </div>

                    <p className="text-white">
                      {tomorrowWeatherData.astro.sunset}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}
