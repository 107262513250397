import { ElectricityRatesChart } from "./ElectricityRatesChart";
import { WeatherWidget } from "./WeatherWidget";
import { HeaderWidget } from "./HeaderWidget";
import CurrentPerformanceWidget from "./CurrentPerformanceWidget";
import { Overview } from "./Overview";
import { ElectricityProductionStats } from "./ElectricityProductionStats";
import { BatteryControlWidget } from "./BatteryControlWidget";
import InformationGraphWidget from "./InformationGraphWidget";
import LiveStatus from "./LiveStatus";
import Animation from "./Animation";
import { useEffect, useState } from "react";
import { synchronize_battery_changes } from "../repositories/BatteryControlRepository";

export function HomePage() {
  const [rerenderTime, setRerenderTime] = useState(new Date());
  const [count, setCount] = useState(0);

  useEffect(() => {
    function scheduleNextUpdate() {
      const sec_refresh = 10;

      const now = new Date();
      const nextUpdate = new Date();

      nextUpdate.setSeconds(now.getSeconds() + sec_refresh);
      nextUpdate.setMilliseconds(0);

      // if (now.getSeconds() >= sec_refresh) {
      //   nextUpdate.setMinutes(nextUpdate.getMinutes() + 1);
      // }

      const timeout = nextUpdate - now;
      console.log("Next update at", nextUpdate.toLocaleTimeString());
      setRerenderTime(nextUpdate);

      setTimeout(async () => {
        console.log("Rerendering");

        await synchronize_battery_changes();

        setCount(count + 1);
        scheduleNextUpdate();
      }, timeout);
    }

    scheduleNextUpdate();
    return () => clearTimeout(scheduleNextUpdate);
  }, []);

  return (
    <div className={"mb-14"}>
      <HeaderWidget refresh_date={rerenderTime} />
      <div
        className={
          "flex flex-row gap-11 mx-6 xs:flex-col xs:mx-4 md:flex-wrap md:gap-8 lap:flex-wrap"
        }
      >
        <WeatherWidget key={`weather-${count}`} refresh={rerenderTime} />
        <CurrentPerformanceWidget
          key={`current-performance-${count}`}
          refresh={rerenderTime}
        />
        <Overview>
          <ElectricityProductionStats
            key={`electricity-production-stats-${count}`}
            refresh={rerenderTime}
          />
        </Overview>
      </div>
      <div
        className={
          "grid grid-cols-2 gap-11 mx-6 mt-6 xs:flex xs:flex-col xs:mx-4 xs:mt-11 md:flex md:flex-wrap md:gap-8 md:mt-8 lap:gap-8 lap:mt-8 lap:flex lap:flex-wrap"
        }
      >
        <BatteryControlWidget
          key={`battery-control-widget-${count}`}
          refresh={rerenderTime}
          synchronization_time={rerenderTime}
        />
        {/* <Animation animationData={prop.animationData}></Animation> */}
        <LiveStatus key={`live-status-${count}`} refresh={rerenderTime} />
      </div>
      {/* <div
        className={
          "grid grid-cols-2 gap-11 mx-6 mt-6  xs:flex xs:flex-col xs:mx-4 xs:mt-11 md:flex md:flex-wrap md:gap-8 md:mt-8 lap:gap-8 lap:mt-8 lap:flex lap:flex-wrap"
        }
      ></div> */}

      <div
        className={
          "gap-11 mx-6 mt-6  xs:flex xs:flex-col xs:mx-4 xs:mt-11 md:flex md:flex-wrap md:gap-8 md:mt-8 lap:gap-8 lap:mt-8 lap:flex lap:flex-wrap"
        }
      >
        <InformationGraphWidget
          key={`information-graph-widget-${count}`}
          refresh={rerenderTime}
        />
      </div>
      <div
        className={
          "gap-11 mx-6 mt-6  xs:flex xs:flex-col xs:mx-4 xs:mt-11 md:flex md:flex-wrap md:gap-8 md:mt-8 lap:gap-8 lap:mt-8 lap:flex lap:flex-wrap"
        }
      >
        <ElectricityRatesChart key={`electricity-rates-chart-${count}`} />
      </div>
    </div>
  );
}
