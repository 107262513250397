import { Button } from "./Button";
import { Input } from "./Input";
import { Select } from "./Select";
import { useEffect, useRef, useState } from "react";
import ConfirmModal from "./ConfirmModal";
import {
  canOperateBattery,
  fetchBatteryInitialValues,
  getScheduledValues,
  saveChanges,
  storeValues,
} from "../repositories/BatteryControlRepository";
import { BatteryStatusEnum } from "../enums/BatteryStatusEnum";
import loading from "../animations/loading.json";
import Animation from "./Animation";
import { BatteryModeEnum } from "../enums/BatteryModeEnum";
import { BatteryControlTypeEnum } from "../enums/BatteryControlTypeEnum";
import Countdown from "./Countdown";
import { BatteryControlKeysEnum } from "../enums/BatteryControlKeysEnum";

function translate_name_text(name) {
  return BatteryControlKeysEnum.get_heading_text(name);
}

const emptyValue = "";

const initial_control_values = {
  [BatteryControlKeysEnum.battery_status]: emptyValue,
  [BatteryControlKeysEnum.battery_mode]: emptyValue,
  [BatteryControlKeysEnum.battery_control_type]: emptyValue,
  [BatteryControlKeysEnum.battery_active_performance]: emptyValue,
};

const translate_values = (obj, values) => {
  obj[BatteryControlKeysEnum.battery_status] = BatteryStatusEnum.toString(
    values.battery_status
  );

  obj[BatteryControlKeysEnum.battery_mode] = BatteryModeEnum.toString(
    values.battery_mode
  );

  obj[BatteryControlKeysEnum.battery_control_type] =
    BatteryControlTypeEnum.toString(values.battery_control_type);

  obj[BatteryControlKeysEnum.battery_active_performance] =
    values.battery_active_performance;
};

export function BatteryControlWidget({ refresh, synchronization_time }) {
  // three selects:
  // I. ON/OFF/RESET
  // II. Manual/Auto-P
  // III. local/remote
  // charge/discharge

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [performanceMin, setPerformanceMin] = useState(0);
  const [performanceMax, setPerformanceMax] = useState(0);

  // stores chosen values in text form
  const [control_values, setControlValues] = useState(initial_control_values);

  // stores chosen values in value form
  const [chosen_values, setChosenValues] = useState({});

  const [initial_values, setInitialValues] = useState({
    ...initial_control_values,
  });

  // values that are scheduled to be changed
  const [scheduledValues, setScheduledValues] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const [isOk, setIsOk] = useState(true);

  const [canOperate, setCanOperate] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  const [reload, setReload] = useState(0);

  const performance_input_ref = useRef(null);

  const handle_input_change = (e) => {
    const input = e.target;
    const name = input.name;
    const selectedOption = input[input.selectedIndex];
    const txt = selectedOption.text;
    const value = selectedOption.value;

    if (name === BatteryControlKeysEnum.battery_mode) {
      setIsDisabled(value != BatteryModeEnum.MANUAL);
      if (isDisabled) {
        if (
          control_values[BatteryControlKeysEnum.battery_active_performance] !==
          undefined
        )
          setControlValues((prev) => {
            return { ...prev, battery_active_performance: 0 };
          });

        if (
          chosen_values[BatteryControlKeysEnum.battery_active_performance] !==
          undefined
        )
          setChosenValues((prev) => {
            return { ...prev, battery_active_performance: 0 };
          });
        performance_input_ref.current.value = "";
      }
    }

    if (selectedOption.classList.contains("defaultOption")) {
      setControlValues((prev) => {
        return { ...prev, [name]: initial_values[name] };
      });

      setChosenValues((prev) => {
        const newValues = { ...prev };
        delete newValues[name];
        return newValues;
      });
      return;
    }

    setControlValues((prev) => {
      return { ...prev, [name]: txt };
    });

    if (selectedOption.classList.contains("resetOption")) {
      setControlValues((prev) => {
        return { ...prev, [name]: emptyValue };
      });
    }

    setChosenValues((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const openModal = () => {
    console.log(initial_values);
    console.log(control_values);
    console.log(chosen_values);
    if (!isOk) {
      alert("Některé hodnoty nejsou v pořádku");
      return;
    }

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const current_value_input = (
    key,
    unit = "",
    val = initial_values[key],
    label = "Aktuální hodnota",
    cname = ""
  ) => {
    return (
      <div className=" justify-between xs:justify-center grd">
        <Input
          type="text"
          placeholder="&#8212;"
          label={label}
          readOnly={true}
          className={`flex-1 border border-gray-100 rounded-lg shadow-sm bg-gray-100 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all w-full text-center font-semibold ${cname}`}
          classLabel="flex-1 font-bold text-gray-700 p-2"
          value={val + unit}
        />
      </div>
    );
  };

  const scheduled_value_input = (key, unit = "") => {
    // if the value is different from the initial value, show it
    // else do not show it
    if (!scheduledValues[key] || scheduledValues[key] === initial_values[key]) {
      return null;
    }

    return current_value_input(
      key,
      unit,
      scheduledValues[key],
      "Plánovaná hodnota",
      "bg-green-300 border-green-300"
    );
  };

  const generate_select = (options, name, label) => {
    return (
      <Select
        placeholder={"Vyberte hodnotu"}
        label={label}
        defaultValue={"-1"}
        options={options}
        classLabel={"font-bold text-center text-lg"}
        name={name}
        onChange={handle_input_change}
        className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all font-bold text-gray-700"
        key={reload + name}
      />
    );
  };

  useEffect(() => {
    async function checkCanOperate() {
      try {
        setIsLoading(true);
        const canOperate = await canOperateBattery();
        setCanOperate(canOperate);
      } catch (error) {
        console.error("Error fetching operating status:", error);
      } finally {
        // setIsLoading(false);
      }
    }

    checkCanOperate();
  }, [refresh]);

  useEffect(() => {
    // load initial values
    // clear chosen values

    setChosenValues({});

    async function getInitialValues() {
      setIsLoading(true);
      try {
        const values = await fetchBatteryInitialValues();
        console.log(values);

        const initialValues = {};
        setPerformanceMin(values.performance_min);
        setPerformanceMax(values.performance_max);

        translate_values(initialValues, values);

        console.log("initialValues", initialValues);
        if (initialValues[BatteryControlKeysEnum.battery_mode] == "Manual") {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }

        setInitialValues(initialValues);
      } catch (error) {
        console.error("Error fetching initial values:", error);
      } finally {
        setIsLoading(false);
      }
    }

    async function getScheduled() {
      try {
        const values = await getScheduledValues(
          BatteryControlKeysEnum.getAllKeys(),
          1
        );

        const changed_values = Object.fromEntries(
          Object.entries(values).map(([key, [value]]) => [key, value])
        );

        const translatedValues = {};
        translate_values(translatedValues, changed_values);
        setScheduledValues(translatedValues);
      } catch (error) {
        console.error("Error fetching scheduled values:", error);
      }
    }

    if (canOperate) {
      getInitialValues();
      // setScheduledValues({});
      getScheduled();
    } else {
      setIsLoading(false);
    }
  }, [canOperate, refresh]);

  const save_changes = async () => {
    // save changes
    if (Object.keys(chosen_values).length === 0) {
      closeModal();
    } else {
      // const response = await saveChanges(chosen_values);
      const response = await storeValues(chosen_values);
      console.log("chosen_values", chosen_values);
      console.log("control_values", control_values);
      setReload((prev) => prev + 1);
      setScheduledValues(control_values);
      // setIsDisabled(true);
      console.log("scheduledValues", control_values);
      performance_input_ref.current.value = "";
      closeModal();
      // setIsDisabled(true);

      // setTimeout(() => {
      //   setReload((prev) => !prev);
      // }, 1500);
    }
  };

  const generate_table_changes = () => {
    const rows =
      Object.keys(chosen_values).length !== 0
        ? Object.keys(chosen_values).map((key) => {
            if (key === "battery_active_performance" && isDisabled) {
              return null;
            }
            return (
              <tr className={"text-center border"} key={key}>
                <td className={"border p-2"}>{translate_name_text(key)}</td>
                <td className={"border p-2"}>{initial_values[key]}</td>
                <td className={"border p-2"}>{control_values[key]}</td>
              </tr>
            );
          })
        : [];

    return (
      <>
        {rows.length !== 0 ? (
          <table className={"mx-auto w-full border mt-11"}>
            <thead>
              <tr className={"text-center border"}>
                <th className={"border p-2"}>Parametr</th>
                <th className={"border p-2"}>Aktuální hodnota</th>
                <th className={"border p-2"}>Nová hodnota</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        ) : (
          <span className="font-bold">Nebyly provedeny žádné změny</span>
        )}
      </>
    );
  };

  if (isLoading) {
    return <Animation animationData={loading} heading={"Ovládání BESS"} />;
  }

  if (!canOperate) {
    return (
      <div className=" bg-white rounded-xl shadow-lg flex-1  py-8 px-8">
        <h1 className="text-2xl font-bold text-center p-4 pb-0">
          Ovládání BESS
        </h1>
        <div className="h-full xs:h-auto text-center mt-20">
          <span className="text-center text-xl font-bold text-red-500">
            Prosím o změnu ovládání na Remote, aby šlo BESS ovládat přes tuto
            aplikaci
          </span>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className=" bg-white rounded-xl shadow-lg flex-1  py-8 px-8">
        <h1 className="text-2xl font-bold text-center p-4 pb-0">
          Ovládání BESS
        </h1>
        <div className="flex justify-center items-center flex-col mt-4">
          <span className="text-center font-bold text-lg">
            Do synchronizace zbývá:{" "}
          </span>
          <Countdown
            till={synchronization_time}
            cname={"text-center text-lg font-bold text-green-600"}
          />
        </div>
        <div className="flex flex-col h-full xs:h-auto">
          <div className="grid grid-cols-2 gap-4 p-4 pt-0 xs:grid-cols-1 xs:gap-2">
            <div className=" p-4 ">
              <div className={"flex flex-col gap-2"}>
                {generate_select(
                  BatteryStatusEnum.generateSelectOptions(
                    initial_values[BatteryControlKeysEnum.battery_status]
                  ),
                  BatteryControlKeysEnum.battery_status,
                  "Změna stavu"
                )}
                {current_value_input(BatteryControlKeysEnum.battery_status)}
                {scheduled_value_input(BatteryControlKeysEnum.battery_status)}
              </div>
            </div>
            <div className=" p-4 ">
              <div className={"flex flex-col gap-2"}>
                {generate_select(
                  BatteryModeEnum.generateSelectOptions(
                    initial_values[BatteryControlKeysEnum.battery_mode]
                  ),
                  BatteryControlKeysEnum.battery_mode,
                  "Přepínání režimů"
                )}
                {current_value_input(BatteryControlKeysEnum.battery_mode)}
                {scheduled_value_input(BatteryControlKeysEnum.battery_mode)}
              </div>
            </div>
            <div className=" p-4 ">
              <div className={"flex flex-col gap-2"}>
                {generate_select(
                  BatteryControlTypeEnum.generateSelectOptions(
                    initial_values[BatteryControlKeysEnum.battery_control_type]
                  ),
                  BatteryControlKeysEnum.battery_control_type,
                  "Způsob ovládání"
                )}
                {current_value_input(
                  BatteryControlKeysEnum.battery_control_type
                )}
                {scheduled_value_input(
                  BatteryControlKeysEnum.battery_control_type
                )}
              </div>
            </div>
            <div className=" p-4 ">
              <div className={"flex flex-col gap-2"}>
                <div className={"flex flex-col mt-3 gap-2"}>
                  <Input
                    type={"number"}
                    placeholder={"Zadejte činný výkon"}
                    label={`Činný výkon (${performanceMin} až ${performanceMax} kW)`}
                    className={
                      "w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all flex-1"
                    }
                    classLabel={"flex-1 font-bold text-center"}
                    min={Math.floor(performanceMin)}
                    max={Math.ceil(performanceMax)}
                    required={true}
                    reference={performance_input_ref}
                    onChange={(e) => {
                      let value = e.target.value;

                      if (isDisabled) {
                        setControlValues((prev) => {
                          return { ...prev, battery_active_performance: 0 };
                        });

                        setChosenValues((prev) => {
                          return { ...prev, battery_active_performance: 0 };
                        });
                      } else {
                        setControlValues((prev) => {
                          return { ...prev, battery_active_performance: value };
                        });

                        setChosenValues((prev) => {
                          return { ...prev, battery_active_performance: value };
                        });
                      }
                    }}
                    updateState={setIsOk}
                    readOnly={isDisabled}
                    k={reload + isDisabled}
                  />
                </div>
                {current_value_input(
                  BatteryControlKeysEnum.battery_active_performance,
                  " kW"
                )}
                {scheduled_value_input(
                  BatteryControlKeysEnum.battery_active_performance,
                  " kW"
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-center pb-6 justify-self-end">
            <Button
              text={"Uložit"}
              class={
                "text-white font-bold py-2 px-4 rounded battery-save-button transition-all duration-300 ease-in-out"
              }
              onClick={openModal}
            ></Button>
          </div>
        </div>
        {isModalOpen && (
          <ConfirmModal
            isOpen={isModalOpen}
            onClose={closeModal}
            headline="Přejete si uložit změny?"
            text={generate_table_changes()}
            buttonText="Close"
            onConfirm={save_changes} // todo: save changes
          />
        )}
      </div>
    </>
  );
}
