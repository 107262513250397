import {
  getRefreshInterval,
  getDataGatherInterval,
} from "../repositories/ElectricityRepository";

import {
  getAnimationRefreshDelay,
  getAnimationRefreshInterval,
} from "../repositories/AnimationRepository";

import RefreshTooltip from "./RefreshTooltip";
import { useEffect, useState } from "react";
import Countdown from "./Countdown";

export function HeaderWidget({ refresh_date }) {
  return (
    <header
      className={
        "flex flex-row justify-between items-center px-8 py-6 bg-white gradient-background xs:flex xs:flex-col xs:gap-3"
      }
    >
      <h1
        className={
          "text-xl font-bold text-custom flex flex-row items-center gap-1"
        }
      >
        DALU <span className={"text-lg font-light"}>monitorovací aplikace</span>
      </h1>
      <div className={"flex gap-8 h-16 items-baseline"}>
        <div className="refresh-intervals flex justify-end ">
          <div className={"font-light text-sm text-custom flex gap-2"}>
            <div className={"font-bold text-custom val"}>
              <Countdown till={refresh_date} />
            </div>
            <div className={"desc "}>do obnovení widgetů</div>
          </div>
        </div>

        <div className="logout flex h-full justify-center items-center relative">
          <a className={"font-bold text-sm text-custom block"} href="/logout">
            Odhlásit se
          </a>
        </div>
      </div>
    </header>
  );
}
