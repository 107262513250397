import { CONFIG } from "./constants";

export class ApiConfig {
  static protocol = CONFIG.PROTOCOL;
  static host = CONFIG.HOST;
  static port = CONFIG.PORT;
  static endpoint = CONFIG.API_ENDPOINT;

  static getFormattedURL() {
    // let host_part = `${this.host}`
    // if (this.port) {
    //     host_part += `:${this.port}`
    // }

    // return `${this.protocol}://${host_part}/${this.endpoint}`
    return `/${this.endpoint}`;
  }
}
