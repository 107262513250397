import { ApiConfig } from "../config/ApiConfig";
import { BatteryControlKeysEnum } from "../enums/BatteryControlKeysEnum";
import { BessStatusEnum } from "../enums/BessStatusEnum";

export async function fetchBatteryInitialValues() {
  const response = await fetch(
    ApiConfig.getFormattedURL() + "/battery/initial-values/all"
  );

  if (!response.ok) {
    throw new Error("Fetching battery initial values failed");
  }

  return response.json();
}

export async function canOperateBattery() {
  const response = await fetch(
    ApiConfig.getFormattedURL() + "/battery/bessStatus"
  );

  if (!response.ok) {
    throw new Error("Fetching battery operating status failed");
  }

  const { operate } = await response.json();
  console.log("operate", operate);

  // return operate !== BessStatusEnum.LOCAL;
  // return true;
  return operate !== 0;
}

export async function saveChanges(changes_obj) {
  console.log("saving changes");
  const response = await fetch(
    ApiConfig.getFormattedURL() + "/battery/values/update",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(changes_obj),
    }
  );

  if (!response.ok) {
    throw new Error("Saving changes failed");
  }

  return response.json();
}

export async function storeValues(values) {
  // stores values in the database

  const response = await fetch(
    ApiConfig.getFormattedURL() + "/battery/values/store",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    }
  );

  if (!response.ok) {
    throw new Error("Storing values failed");
  }

  return response.json();
}

export async function getScheduledValues(keys, dataValidity) {
  const response = await fetch(
    ApiConfig.getFormattedURL() + `/battery/values/scheduled/${dataValidity}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(keys),
    }
  );

  console.log("response", response);

  if (!response.ok) {
    throw new Error("Fetching scheduled values failed");
  }

  return response.json();
}

export async function deleteScheduledChanges(data_validity_time) {
  const response = await fetch(
    ApiConfig.getFormattedURL() +
      `/battery/values/scheduled/delete/${data_validity_time}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(BatteryControlKeysEnum.getAllKeys()),
    }
  );

  if (!response.ok) {
    throw new Error("Deleting scheduled values failed");
  }

  return response.json();
}

// extracts battery values (first value in array) from an object
// data example:
// {
//   battery_status: [1, 1],
//   battery_mode: [2, 2],
//   battery_control_type: [3, 3],
//   battery_active_performance: [null, 4],
// }
export function extractBatteryValues(data) {
  return Object.fromEntries(
    Object.entries(data)
      .filter(([_, [value]]) => value !== null) // Exclude entries where first value is null
      .map(([key, [value]]) => [key, value])
  );
}

// similar, only extracts battery ids (second value in array)
export function extractBatteryIds(data) {
  return Object.fromEntries(
    Object.entries(data)
      .filter(([_, [, id]]) => id !== null) // Exclude entries where second value is null
      .map(([key, [, id]]) => [key, id])
  );
}

export async function synchronize_battery_changes() {
  const changes = await getScheduledValues(
    BatteryControlKeysEnum.getAllKeys(),
    1
  );

  const changes_values = extractBatteryValues(changes);

  console.log("changes_values", changes_values);

  await saveChanges(changes_values);

  // if (!(await deleteScheduledChanges(1))) {
  //   throw new Error("Deleting scheduled values failed");
  // }
  const r = await deleteScheduledChanges(1);
  if (!r) {
    throw new Error("Deleting scheduled values failed");
  }
  console.log("r", r);
}
