import { ApiConfig } from "../config/ApiConfig";

export async function fetchDataSchemas(date, schema) {
  const query = {
    startTime: new Date(`${date}T00:00:00Z`), // Start of the specified date
    endTime: new Date(`${date}T23:59:59.999Z`), // End of the specified date
  };

  const result = await fetch(
    ApiConfig.getFormattedURL() + `/schemas/${schema}`,
    {
      method: "POST",
      body: JSON.stringify({ query }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!result.ok) {
    throw new Error("Error getting graph data");
  }

  return result.json();
}
