import { BaseEnum } from "./core/BaseEnum";

export class BatteryControlKeysEnum {
  static battery_status = "battery_status";
  static battery_mode = "battery_mode";
  static battery_control_type = "battery_control_type";
  static battery_active_performance = "battery_active_performance";

  static get_heading_text(key) {
    switch (key) {
      case this.battery_status:
        return "Změna stavu";
      case this.battery_mode:
        return "Přepínání režimů";
      case this.battery_control_type:
        return "Způsob ovládání";
      case this.battery_active_performance:
        return "Činný výkon";
      default:
        return "Neznámý";
    }
  }

  static getAllKeys() {
    return [
      this.battery_status,
      this.battery_mode,
      this.battery_control_type,
      this.battery_active_performance,
    ];
  }
}
